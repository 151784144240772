<template>
    <div class="page-content">
        <page-breadcrumb v-if="item" title="Topic Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
        <b-card class="mt-1" v-if="item">
            <form-generator :model="item" :schema="item_form_schema" />
        </b-card>
        </section>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>

</template>
<script>
const item_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumbnail', field: 'thumbnail', input_type: 'image', validate: { required: true } },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', input_type:'ui-component', validate: { required: true } },
      { label: 'Type', field: 'type', input_type: 'select', options: [{text: "GEM", value: 1}], validate: { required: true } },
      { label: 'Store Id', field: 'store_id', validate: { required: true } },
      { label: 'Discount info', field: 'discount_info', input_type: 'text' },
      { label: 'Tag', field: 'tag', input_type: 'text' },
    ]
  }
];
import service from '../service'
export default {
    data(){
        return{
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Iap Products',
                    to: { name: 'iap-product-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created(){
        this.getProduct()
    },
    methods:{
        async getProduct(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            let data = {...this.item};
            data.name = JSON.stringify(data.name)
            await service.update({
                data: JSON.stringify(data),
            });
            this.getProduct();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "iap-product-list" });
        },
    }
}
</script>
